// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  doc,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc,
  type CollectionReference,
  type DocumentReference,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import type { UserState } from "src/context/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC553H5ZK5nPWvh-PGuU0Glf88KZo2pQYA",
  authDomain: "psychocare-erp-dev.firebaseapp.com",
  projectId: "psychocare-erp-dev",
  storageBucket: "psychocare-erp-dev.appspot.com",
  messagingSenderId: "894210422060",
  appId: "1:894210422060:web:81174e3a543081b3b0a889",
  measurementId: "G-7YS5YZC25X",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app, "europe-central2");
const defaultAvatarUrl =
  "https://practicare-storage.s3.eu-central-1.amazonaws.com/assets/avatar.png";

const cloudFunctionUpdateUserEmail = httpsCallable(
  functions,
  "usersUpdateUserEmail"
);
const cloudFunctionsPaymentCreate = httpsCallable(functions, "paymentCreate");

const cloudFunctionsPhoneNumberVerify = httpsCallable(
  functions,
  "customersVerifyPhoneNumber"
);
const cloudFunctionsMergeCustomers = httpsCallable(
  functions,
  "customersMergeRequest"
);
const appointmentsGenerateReportRequest = httpsCallable(
  functions,
  "appointmentsGenerateReportRequest"
);
const customersSendGdprRequest = httpsCallable(
  functions,
  "customersSendGdprRequest"
);
const cloudFunctionsSendLoginEmail = httpsCallable(
  functions,
  "usersLoginWithEmail"
);
const secretsSetSecretRequest = httpsCallable(
  functions,
  "secretsSetSecretRequest"
);
const usersCreateUser = httpsCallable(functions, "usersCreateUser");
const cloudFunctionUsersSendResetPassword = httpsCallable(
  functions,
  "usersSendResetPassword"
);
const cloudFunctionPaymentsImport = httpsCallable(functions, "paymentsImport");
const cloudFunctionGetCouponDetails = httpsCallable(functions, "couponDetails");

const getUserData = (user: UserState) => ({
  id: user?.userData?.id,
  firstName: user?.userData?.firstName,
  lastName: user?.userData?.lastName,
  avatar: user?.userData?.avatar || defaultAvatarUrl,
});

const updateDocWithUser = (
  reference: DocumentReference,
  data: any,
  user: UserState
) =>
  updateDoc(reference, {
    ...data,
    updatedBy: getUserData(user),
    updatedAt: serverTimestamp(),
  });

const setDocWithUser = (
  reference: DocumentReference,
  data: any,
  user: UserState
) =>
  setDoc(reference, {
    ...data,
    updatedBy: getUserData(user),
    updatedAt: serverTimestamp(),
  });

const addDocWithUser = (
  reference: CollectionReference,
  data: any,
  user: UserState
) =>
  addDoc(reference, {
    ...data,
    isDeleted: false,
    createdBy: getUserData(user),
    createdAt: serverTimestamp(),
  });

const deleteDocument = (
  collectionName: string,
  docId: string,
  user: UserState
) =>
  updateDoc(doc(db, collectionName, docId), {
    isDeleted: true,
    deletedBy: getUserData(user),
    deletedAt: serverTimestamp(),
  });

export {
  addDocWithUser,
  analytics,
  app,
  appointmentsGenerateReportRequest,
  auth,
  cloudFunctionGetCouponDetails,
  cloudFunctionPaymentsImport,
  cloudFunctionsMergeCustomers,
  cloudFunctionsPaymentCreate,
  cloudFunctionsPhoneNumberVerify,
  cloudFunctionsSendLoginEmail,
  cloudFunctionUpdateUserEmail,
  cloudFunctionUsersSendResetPassword,
  customersSendGdprRequest,
  db,
  deleteDocument,
  firebaseConfig,
  secretsSetSecretRequest,
  setDocWithUser,
  storage,
  updateDocWithUser,
  usersCreateUser,
};
